body, p, h3, h1, h2, h4, h5 {
  padding: 0;
  margin: 0;
  //font-size: inherit;
}

h3, h1, h2, h4, h5 {
  font-weight: bold;
}

#app {
  /*  min-width: 1250px;
    overflow-x: scroll;*/
  height: 100vh;
  //overflow-y: scroll;
  overflow-y: hidden;
  font-family: PingFang SC;
}

.maxHeight {
  padding: 14px 14px 0;
  height: 100%;
}

.flex {
  display: flex;
  align-items: center;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar { /*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
  width: 5px;
  height: 5px;
}


::-webkit-scrollbar-track { /*外层轨道，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置3）*/
  display: none;
}

::-webkit-scrollbar-track-piece { /*内层轨道，滚动条中间部分（位置4）*/
  display: none;
}

::-webkit-scrollbar-thumb { /*滚动条里面可以拖动的那部分（位置5）*/
  background: #5B8BFF;
  border-radius: 2px;
}

body {
  /* -ms-scroll-chaining: chained;
   -ms-overflow-style: none;
   -ms-content-zooming: zoom;
   -ms-scroll-rails: none;
   -ms-content-zoom-limit-min: 100%;
   -ms-content-zoom-limit-max: 500%;
   -ms-scroll-snap-type: proximity;
   -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
   -ms-overflow-style: none;
   overflow: auto*/
  scrollbar-arrow-color: #fff; /**//*三角箭头的颜色*/
  scrollbar-face-color: #5B8BFF; /**//*立体滚动条的颜色*/
  scrollbar-3dlight-color: #5B8BFF; /**//*立体滚动条亮边的颜色*/
  scrollbar-highlight-color: #5B8BFF; /**//*滚动条空白部分的颜色*/
  scrollbar-shadow-color: #5B8BFF; /**//*立体滚动条阴影的颜色*/
  scrollbar-darkshadow-color: #5B8BFF; /**//*立体滚动条强阴影的颜色*/
  scrollbar-track-color: #fff; /**//*立体滚动条背景颜色*/
  scrollbar-base-color: #fff; /**//*滚动条的基本颜色*/
}

.header {
  justify-content: space-between;

  h2 {
    font-size: 22px;
    font-weight: 500;
    color: #213333;
    margin: 0 10px;
  }

  .num {
    font-size: 14px;
    font-weight: 500;
    color: #4F9AFE;
    margin-left: 10px;
  }

  .el-button {
    border: none;
    background: #4F9AFE;
    border-radius: 0;
    color: #FFFFFF;
  }
}

.contentBody {
  padding: 16px;
  background: #FFFFFF;
  margin-top: 20px;
  height: 93%;
  overflow-y: scroll;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  /*  .el-table td {
      padding: 4px 0;
    }*/
}

.el-table--border, .el-table--group,
.el-table--border td, .el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-color: #D5EAFF;
  //background: #fff;
}

.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row--striped.current-row > td,
.el-table__body tr.hover-row.el-table__row--striped > td,
.el-table__body tr.hover-row > td {
  background: inherit;
}

.el-table--border::after, .el-table--group::after, .el-table::before {
  display: none;
}

.tableHead {
  justify-content: space-between;

  .el-button {
    border: none;
    background: #FFAB57;
    border-radius: 0;
    color: #FFFFFF;
  }

  .el-input {
    width: 241px;
  }

  .el-input__inner {
    height: 28px;
    width: 100%;
    line-height: 28px;
    border-color: #4F9AFE;
  }

  .el-input__icon {
    line-height: 28px;
    color: #4F9AFE;
  }
}

.between {
  justify-content: space-between;
}

.el-table--enable-row-hover .el-table__body tr:hover > td {
  background: transparent;
}

.el-input__inner::-webkit-input-placeholder, .el-textarea__inner::-webkit-input-placeholder {
  color: #999999
}

.el-input__inner:-moz-placeholder, .el-textarea__inner:-moz-placeholder {
  color: #999999
}


.el-input__inner:-ms-input-placeholder, .el-textarea__inner:-ms-input-placeholder {
  color: #999999
}

/*.el-table th, .el-table tr {
  background-color: inherit;
}*/

.btnClass .el-button {
  background: rgba(213, 234, 255, 0.5);
  color: #006dff;
  font-weight: 500;
  border: none;

  &.is-disabled, &.is-disabled:focus, &.is-disabled:hover {
    background: rgba(213, 234, 255, 0.5);
    color: #006dff;
  }
}

.pagination {
  margin-top: 20px;

  .number {
    width: 28px;
    height: 28px;
    border: 1px solid #4F9AFE;
    margin: 0 8px;
    color: #4F9AFE;

    &.active {
      background: #4F9AFE;
      color: #FFFFFF;
    }
  }

  .btn-next, .btn-prev {
    border: 1px solid #4F9AFE;
    padding: 0;
  }

  .total {
    color: #4F9AFE;
    margin-left: 16px;
  }

  .el-pager li.active + li {
    border-left: 1px solid #4F9AFE;
  }
}

.commonDialog {
  justify-content: center;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    color: #333333;
    text-align: center;
    line-height: 2;
    padding: 25px 25px 10px;
    font-weight: bolder;

    img {
      margin-bottom: 20px;
    }
  }

  .el-button {
    background: #4F9AFE;
    border-radius: 4px;
    color: #FFFFFF;
    border: none;

    &.is-plain {
      border: 1px solid #4F9AFE;
      color: #4F9AFE;
      background: #FFFFFF;
    }
  }
}

.radioGroup {

  .el-radio__label {
    color: #333333;
  }

  .el-radio__inner {
    border: 1px solid #CCCCCC;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background-color: inherit;

    &::after {
      width: 11px;
      height: 11px;
      background: #4877E8;
      border-radius: 50%;
    }
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #4877E8;
    background-color: transparent;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #333333;
  }
}

.addForm {
  .smallWidth {
    width: 216px;
  }

  .tit {
    color: #333333;
    border-bottom: 1px solid rgba(130, 182, 253, 0.5);
    margin-bottom: 16px;
    padding-bottom: 12px;
    padding-left: 12px;
    font-size: 16px;
    font-weight: bold;
  }

  .el-input, .el-textarea {
    max-width: 890px;
  }

  .el-input__suffix-inner {
    color: #4F9AFE;
  }

  .el-input__inner {
    border-radius: 0;
    border-color: #BBBBBB;
    color: #333333;
  }

  .el-input__suffix {
    line-height: 32px;
  }

  .el-textarea__inner {
    border-radius: 0;
    border-color: #BBBBBB;
    resize: none;
    font-family: "Arial";
    color: #333333;
  }

  .head .el-form-item {
    margin-right: 20px;
  }

  .el-form-item__label {
    color: #333333;
  }

  .el-select .el-input__inner[readonly="readonly"] {
    border: 1px solid #BBBBBB;
  }
}

.el-input.is-active .el-input__inner, .el-input__inner:focus {
  border-color: #BBBBBB;
}

.fromSave {
  margin: 15px 0;

  .el-button {
    border-color: #FFAB57;
    background-color: #FFAB57;
    min-width: 88px;
    border-radius: 0;

    &.is-plain {
      color: #FFAB57;
      background-color: #FFFFFF;

      &:hover {
        border-color: #FFAB57;
      }
    }
  }

  .el-button--primary.is-disabled:active,
  .el-button--primary.is-disabled:focus,
  .el-button--primary.is-disabled:hover {
    border-color: #FFAB57;
    color: #ffffff;
    background-color: #FFAB57;
  }

  .el-button--primary.is-disabled.is-plain,
  .el-button--primary.is-disabled.is-plain:active,
  .el-button--primary.is-disabled.is-plain:focus,
  .el-button--primary.is-disabled.is-plain:hover {
    border-color: #FFAB57;
    color: #FFAB57;
    background-color: #ffffff;
  }
}

.export {
  position: absolute;
  //background: #4F9AFE;
  //border: none;
  border-color: #4F9AFE;
  color: #4F9AFE;
  right: 16px;
  border-radius: 0;

  .el-button {
    border-color: #4F9AFE;
    color: #4F9AFE;
    border-radius: 0;
  }

  &:hover, &:focus {
    background: transparent;
    color: #4F9AFE;
    border-color: #4F9AFE;
  }
}

.relative {
  position: relative;
}

.table, .table tr th, .table tr td {
  border: 1px solid #BBBBBB;
}

.table {
  //line-height: 50px;
  width: 877px;
  text-align: center;
  border-collapse: collapse;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  margin: 5px 0 16px;
  line-height: 50px;

  &.specTable {
    line-height: 32px;
  }

  .text {
    padding: 0 10px 5px;
    border-bottom: 1px solid #BBBBBB;
    color: #666666;
  }

  .specTd {
    text-align: left;
    padding-left: 16px;
  }

  .way {
    line-height: 3;
  }
}

.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #4877E8;
  border-color: #4877E8;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #4877E8;
}

.dialogForm {
  .el-dialog__header {
    border-bottom: 1px solid rgba(130, 182, 253, 0.5);
    font-size: 16px;
    padding: 20px 0 10px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    margin: 0 16px;
  }

  .el-dialog__body {
    max-height: 572px;
    overflow-y: scroll;
    padding: 20px 20px 0;
  }


  .el-dialog__headerbtn .el-dialog__close {
    color: #4F9AFE;
  }

  .el-button--primary {
    background: #4F9AFE;
    border-radius: 0;
    min-width: 77px;

    &.is-plain {
      background-color: transparent;
      border-color: #4F9AFE;
      color: #4F9AFE;
    }
  }

  .el-select {
    width: 100%;
  }

  .ant-select-selection {
    border-radius: 0;
    border-color: #BBBBBB;
    color: #333333;
  }
}

.el-table__fixed-right::before, .el-table__fixed::before {
  display: none;
}

.el-button {
  border-radius: 0;
}

.el-tabs--card > .el-tabs__header {
  border: none;

  .el-tabs__nav {
    border: none;
  }

  .el-tabs__item:first-child {
    border-left: 1px solid #4F9AFE;
  }
}

.drawerCommon {
  .el-drawer {
    padding: 20px;
  }

  .el-drawer__header {
    margin-bottom: 0;
    color: #333333;
    padding: 0 8px 12px;
    font-size: 22px;
    border-bottom: 1px solid #82B6FD;

    .el-drawer__close-btn {
      color: #4F9AFE;
    }
  }

  .el-drawer__body {
    padding: 16px 7px;

    .el-button--text {
      color: #FFAB57;
    }

    .btn {
      float: right;

      .el-button--primary, .el-button--primary:focus, .el-button--primary:hover {
        background-color: #4F9AFE;
        border-color: #4F9AFE;
      }

      .el-button.is-plain, .el-button.is-plain:focus, .el-button.is-plain:hover {
        border-color: #4F9AFE;
        color: #4F9AFE;
      }
    }
  }
}

.ant-select-dropdown {
  z-index: 5001;
}

.overFlow {
  display: inline-flex;
  justify-content: center;
  width: 100px;
  height: 100%;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}


