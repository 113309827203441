.table-nest {
    .el-input__inner{
        border:none !important;
    }
    .el-textarea__inner {
        border:none !important;
    }
}

.isDel_text {
    color:#ccc !important;
}
.whiteColor {
    background:#ffffff !important;
}
.notMarginPadding {
    margin: 0;
    padding:0 !important;
    .el-input__inner {
        background-color: transparent;
        color:inherit;
    }
}

.flex-box {
    display: flex;
    align-items: center;
}